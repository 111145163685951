@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
//@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';

#aboutText {
  font-size: $h6-font-size;

  @include media-breakpoint-up(md) {
    font-size: $h5-font-size;
  }

  @include media-breakpoint-up(xxl) {
    font-size: $h3-font-size;
  }
}
