@import '../../styles/colors';
@import '../../styles/typography';

.nav-item:has(.active) {
  background-color: $secondary;
}

.nav-item .active {
  font-weight: $font-weight-bold;
}
