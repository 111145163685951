@font-face {
  font-family: Pixellari;
  src: url('./fonts/Pixellari.ttf');
}

$font-size-base: .875rem;
$font-weight-bold: 700;
$link-decoration: none;
$link-hover-decoration: underline;
$font-family-base: Pixellari, Consolas, monospace;
$font-family-monospace: Pixellari, Consolas, monospace;
