@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.imageSize {
  height: 3rem;
}

@include media-breakpoint-up(lg) {
  .imageSize {
    height: 4rem;
  }
}

