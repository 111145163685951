#side-background {
  background-image: url('../images/ui/home-background.gif');
  background-size: 4rem;
}

.pixelated {
  image-rendering: pixelated;
}

img {
  @extend .pe-none, .pixelated;
}
